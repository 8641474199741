import React, { useState, useEffect } from "react";
import { axiosAuth } from "../interceptors";

import {
  Button,
  Modal,
  TextField,
  Checkbox,
  Box,
  FormControlLabel,
  Autocomplete,
} from "@mui/material";
import md5 from "md5";
import {
  AddRounded as AddRoundedIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
  CheckBox as CheckBoxIcon,
  Edit as EditIcon,
} from "@mui/icons-material";

import OverviewTable from "../components/OverviewTable";
import { useNavigate } from "react-router-dom";

export default function Home() {
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);

  const [open, setOpen] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [roles, setRoles] = useState([]);

  function handleSubmit(event) {
    event.preventDefault();
    let inputRoles = null;
    if (roles.length !== 0) {
      if (roles.includes("superadmin")) setRoles(["superadmin", "admin"]);
      inputRoles = roles.join(",");
    }
    const data = new FormData(event.currentTarget);
    axiosAuth
      .post(process.env.REACT_APP_SERVER_HOST + "/user/register", {
        email: data.get("email"),
        password: data.get("password") ? md5(data.get("password")) : null,
        firstName: data.get("firstname") || "",
        lastName: data.get("lastname") || "",
        phone: data.get("phone") || "",
        roles: inputRoles,
      })
      .then((response) => {
        setOpen(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    axiosAuth
      .get(process.env.REACT_APP_SERVER_HOST + "/user")
      .then((response) => {
        response.data.map((user) => {
          user.naam = `${user.voornaam || ""} ${user.achternaam || ""}`;
          user.rollen = user.rollen
            ? user.rollen.split(",").sort().join(", ")
            : "";
          return user;
        });
        setUsers(response.data);
      });
  }, []);

  const rolesOptions = ["admin", "superadmin"];

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const headCells = [
    {
      id: "naam",
      label: "Naam",
      sort: true,
      style: { width: "25%" },
    },
    {
      id: "email",
      label: "Email",
      sort: true,
      style: { width: "25%" },
    },
    {
      id: "rollen",
      label: "Rollen",
      sort: true,
      style: { width: "20%" },
    },
    {
      id: "telefoon",
      label: "Telefoon",
      sort: true,
      style: { width: "20%" },
    },
    {
      id: "actions",
      label: "Acties",
      sort: false,
      style: { width: "10%" },
    },
  ];

  return (
    <>
      <OverviewTable
        title="Gebruikers"
        headCells={headCells}
        rows={users}
        actions={[
          {
            icon: <EditIcon />,
            title: "Bewerken",
            onClick: (userId) => {
              navigate("/gebruiker/" + userId);
            },
          },
        ]}
        specialActions={[
          {
            icon: <AddRoundedIcon />,
            title: "Toevoegen",
            onClick: (event, rowData) => {
              handleOpen();
            },
            type: "button",
          },
        ]}
      />

      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            borderRadius: "8px",
            p: 4,
          }}
        >
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Adres"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              fullWidth
              id="firstname"
              label="Voornaam"
              name="firstname"
              autoComplete="firstname"
            />
            <TextField
              margin="normal"
              fullWidth
              id="lastname"
              label="Achternaam"
              name="lastname"
              autoComplete="lastname"
            />
            <TextField
              margin="normal"
              fullWidth
              id="phone"
              label="Telefoon"
              name="phone"
              autoComplete="phone"
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={isAdmin}
                  onChange={(e) => setIsAdmin(e.target.checked)}
                />
              }
              label="Admin"
            />

            {isAdmin && (
              <>
                <Autocomplete
                  multiple
                  id="roles"
                  limitTags={2}
                  options={rolesOptions}
                  disableCloseOnSelect
                  sx={{
                    flex: 1,
                    mt: 1,
                  }}
                  getOptionLabel={(option) => option}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Rollen"
                      placeholder="Rollen"
                    />
                  )}
                  onChange={(e, value) => {
                    setRoles(value);
                  }}
                />

                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Wachtwoord"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                />
              </>
            )}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Voeg toe
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
}
