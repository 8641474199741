import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import { Link } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import {
  Person as PersonIcon,
  Church as ChurchIcon,
  Folder as FolderIcon,
} from "@mui/icons-material";
import { AppContext } from "../context/appContext";
import { useContext, useState } from "react";
import Cookies from "js-cookie";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  width: `calc(${theme.spacing(7)} + 1px)`,
  overflowX: "hidden",
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

export default function Sidebar({ open }) {
  const theme = useTheme();
  const { setHeader } = useContext(AppContext);
  const [roles] = useState(Cookies.get("roles") || []);

  const Item = (props) => {
    return (
      <ListItem key={props.title} disablePadding>
        <ListItemButton
          component={Link}
          to={props.link}
          onClick={() => setHeader(props.title)}
        >
          <ListItemIcon>
            <props.icon />
          </ListItemIcon>
          <ListItemText primary={props.title} />
        </ListItemButton>
      </ListItem>
    );
  };

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: drawerWidth,
          boxSizing: "border-box",
        },
        ...(open && {
          ...openedMixin(theme),
          "& .MuiDrawer-paper": openedMixin(theme),
        }),
        ...(!open && {
          ...closedMixin(theme),
          "& .MuiDrawer-paper": closedMixin(theme),
        }),
      }}
    >
      <Toolbar />
      <Box>
        <List>
          <Item title={"Overzicht"} icon={FolderIcon} link={""} />
          <Item title={"Gebouwen"} icon={ChurchIcon} link={"gebouwen"} />
          <Item title={"Categorieën"} icon={InboxIcon} link={"categorieen"} />
          <Item
            title={"Mail Archief"}
            icon={MailOutlineIcon}
            link={"mailarchief"}
          />
        </List>

        {roles.includes("superadmin") && (
          <>
            <Divider />
            <List>
              <Item
                title={"Gebruikers"}
                icon={PersonIcon}
                link={"gebruikers"}
              />
            </List>
          </>
        )}
      </Box>
    </Drawer>
  );
}
