import React, { useEffect, useState } from "react";
import { axiosAuth } from "../interceptors";
import {
  AddRounded as AddRoundedIcon,
  InfoOutlined as InfoOutlinedIcon,
} from "@mui/icons-material";
import OverviewTable from "../components/OverviewTable";
import { useNavigate } from "react-router-dom";

export default function Overview() {
  const navigate = useNavigate();

  const [items, setItems] = useState([]);
  const [items_archief, setItems_archief] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [categories, setCategories] = useState([]);
  const [churches, setChurches] = useState([]);

  const fetchitemData = () => {
    axiosAuth(process.env.REACT_APP_SERVER_HOST + "/items").then((response) => {
      response.data.forEach((item) => {
        if (!item.begindatum) item.begindatum = "";
        if (!item.einddatum) item.einddatum = "";
      });
      setItems(response.data);
    });

    axiosAuth(process.env.REACT_APP_SERVER_HOST + "/items/archive").then(
      (response) => {
        response.data.forEach((item_archief) => {
          if (!item_archief.begindatum) item_archief.begindatum = "";
          if (!item_archief.einddatum) item_archief.einddatum = "";
        });
        setItems_archief(response.data);
      }
    );

    axiosAuth(process.env.REACT_APP_SERVER_HOST + "/items/notifications").then(
      (response) => {
        response.data.forEach((notifications) => {
          if (!notifications.begindatum) notifications.begindatum = "";
          if (!notifications.einddatum) notifications.einddatum = "";
        });
        setNotifications(response.data);
      }
    );

    axiosAuth(process.env.REACT_APP_SERVER_HOST + "/categorieen").then(
      (response) => {
        setCategories(response.data);
      }
    );

    axiosAuth(process.env.REACT_APP_SERVER_HOST + "/kerkgebouwen").then(
      (response) => {
        setChurches(response.data);
      }
    );
  };
  useEffect(() => {
    fetchitemData();
  }, []);

  const headCells = [
    {
      id: "naam",
      label: "Naam",
      sort: true,
      style: { width: "20%" },
    },
    {
      id: "categorie_naam",
      label: "Categorie",
      sort: true,
      style: { width: "20%" },
    },
    {
      id: "kerk_naam",
      label: "Gebouw",
      sort: true,
      style: { width: "20%" },
    },
    {
      id: "begindatum",
      label: "Begindatum",
      sort: true,
      style: { width: "15%" },
      type: "date",
    },
    {
      id: "einddatum",
      label: "Einddatum",
      sort: true,
      style: { width: "15%" },
      type: "date",
    },
    {
      id: "actions",
      label: "Acties",
      sort: false,
      style: { width: "10%" },
    },
  ];

  return (
    <>
      {/* Notifications */}
      <OverviewTable
        title="Meldingen"
        headCells={headCells}
        rows={notifications}
        actions={[
          {
            icon: <InfoOutlinedIcon />,
            title: "Details",
            onClick: (itemId) => {
              navigate("/item/" + itemId);
            },
          },
        ]}
        filters={[
          {
            type: "textfield",
            title: "Naam",
            style: { flex: 1, mr: 2 },
            id: "naam",
          },
          {
            type: "autocomplete",
            title: "Categorie",
            style: { flex: 1, mr: 2 },
            id: "categorie_naam",
            options: categories,
            limitTags: 2,
          },
          {
            type: "autocomplete",
            title: "Gebouw",
            style: { flex: 1 },
            id: "kerk_naam",
            options: churches,
            limitTags: 2,
          },
        ]}
        standardRowsPerPage={5}
      />
      {/* Items */}
      <OverviewTable
        title="Overzicht"
        headCells={headCells}
        rows={items}
        specialActions={[
          {
            icon: <AddRoundedIcon />,
            title: "Toevoegen",
            onClick: (event, rowData) => {
              navigate("/item/create");
            },
            type: "button",
          },
        ]}
        actions={[
          {
            icon: <InfoOutlinedIcon />,
            title: "Details",
            onClick: (itemId) => {
              navigate("/item/" + itemId);
            },
          },
        ]}
        filters={[
          {
            type: "textfield",
            title: "Naam",
            style: { flex: 1, mr: 2 },
            id: "naam",
          },
          {
            type: "autocomplete",
            title: "Categorie",
            style: { flex: 1, mr: 2 },
            id: "categorie_naam",
            options: categories,
            limitTags: 2,
          },
          {
            type: "autocomplete",
            title: "Gebouw",
            style: { flex: 1 },
            id: "kerk_naam",
            options: churches,
            limitTags: 2,
          },
        ]}
      />
      {/* Archief */}
      <OverviewTable
        title="Archief"
        headCells={headCells}
        rows={items_archief}
        actions={[
          {
            icon: <InfoOutlinedIcon />,
            title: "Details",
            onClick: (itemId) => {
              navigate("/item/" + itemId);
            },
          },
        ]}
        filters={[
          {
            type: "textfield",
            title: "Naam",
            style: { flex: 1, mr: 2 },
            id: "naam",
          },
          {
            type: "autocomplete",
            title: "Categorie",
            style: { flex: 1, mr: 2 },
            id: "categorie_naam",
            options: categories,
            limitTags: 2,
          },
          {
            type: "autocomplete",
            title: "Gebouw",
            style: { flex: 1 },
            id: "kerk_naam",
            options: churches,
            limitTags: 2,
          },
        ]}
      />
    </>
  );
}
