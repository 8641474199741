import Cookies from "js-cookie";

export function setSession(session) {
  Cookies.set("x-access-token", session.accessToken);
  Cookies.set("x-refresh-token", session.refreshToken, { expires: 7 });
  Cookies.set("_id", session._id, { expires: 7 });
  Cookies.set("name", session.name, { expires: 7 });
  Cookies.set("loggedIn", true, { expires: 7 });
  Cookies.set("roles", session.roles, { expires: 7 });
}

export function removeSession() {
  Cookies.remove("x-access-token");
  Cookies.remove("x-refresh-token");
  Cookies.remove("_id");
  Cookies.remove("name");
  Cookies.remove("loggedIn");
  Cookies.remove("roles");
}

export function setTokens(tokens) {
  Cookies.set("x-access-token", tokens.accessToken);
  Cookies.set("x-refresh-token", tokens.refreshToken);
}
