import axios from "axios";
import Cookies from "js-cookie";
import { logoutUser } from "../services/UserService";

export const axiosAuth = axios.create();

axiosAuth.interceptors.request.use(
  (config) => {
    const accessToken = Cookies.get("x-access-token");
    if (accessToken) {
      config.headers["x-access-token"] = accessToken;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosAuth.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    const refreshToken = Cookies.get("x-refresh-token");
    if (
      refreshToken &&
      error.response?.status === 401 &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      return await axios
        .post(
          process.env.REACT_APP_SERVER_HOST + "/user/refresh-tokens",
          {},
          {
            headers: {
              "x-refresh-token": refreshToken,
              "id": Cookies.get("_id"),
            },
          }
        )
        .then((response) => {
          Cookies.set("x-access-token", response.data.accessToken);
          Cookies.set("x-refresh-token", response.data.refreshToken, {
            expires: 7,
          });
          axiosAuth.defaults.headers.common["x-access-token"] =
            response.data.accessToken;
          originalRequest.headers["x-access-token"] = response.data.accessToken;
          return axios(originalRequest);
        })
        .catch((err) => {
          logoutUser();
        });
    }
    return Promise.reject(error);
  }
);
