import React from "react";
import { TextField } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import ClearIcon from "@mui/icons-material/Clear";
import IconButton from "@mui/material/IconButton";

const DatePickerClear = (props) => {
  const { onClear } = props;
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        {...props}
        error={false}
        helperText={null}
        format="YYYY-MM-DD"
        slots={{
          textField: (params) => (
            <div
              style={{
                position: "relative",
                display: "inline-block",
                // width: "100%",
              }}
            >
              <TextField {...params} error={false} />
              {props.value && (
                <IconButton
                  style={{
                    position: "absolute",
                    top: "1rem",
                    margin: "auto",
                    right: "2rem",
                  }}
                  onClick={() => onClear()}
                >
                  <ClearIcon />
                </IconButton>
              )}
            </div>
          ),
        }}
      />
    </LocalizationProvider>
  );
};

export default DatePickerClear;
