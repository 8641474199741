import * as React from "react";
import { Link, useNavigate } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { logoutUser } from "../services/UserService";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { useContext } from "react";
import { AppContext } from "../context/appContext";
import Cookies from "js-cookie";
import Sidebar from "./Sidebar";
import { CssBaseline } from "@mui/material";
import { useState } from "react";

export default function Navbar(props) {
  const navigate = useNavigate();
  const { loggedIn, setLoggedIn, header, setHeader } = useContext(AppContext);
  const [open, setOpen] = useState(true);

  const handleDrawer = () => {
    setOpen(!open);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Toolbar>
          {loggedIn ? (
            <IconButton
              size="large"
              edge="start"
              onClick={handleDrawer}
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
            >
              <MenuIcon />
            </IconButton>
          ) : (
            <img
              className="logoIMG"
              src={process.env.PUBLIC_URL + "/pgzlogo.png"}
              alt="logo"
              onClick={() => navigate("/")}
            />
          )}
          <Typography
            component="div"
            variant="h6"
            sx={{ flexGrow: 1, marginLeft: 2, width: "0", cursor: 'pointer' }}
            onClick={() => navigate("/")}
          >
            {header}
          </Typography>
          <Typography
            component="div"
            variant="h6"
            sx={{
              flexGrow: 1,
              marginLeft: 2,
              fontSize: "1.8rem",
              fontFamily: "Corbel",
              flex: 1,
            }}
          >
            {Cookies.get("name")}
          </Typography>
          {loggedIn ? (
            <Button
              color="inherit"
              component={Link}
              to="/"
              onClick={() => {
                setHeader("Home");
                logoutUser();
                setLoggedIn(false);
              }}
              sx={{ fontSize: "1.3rem", fontFamily: "Corbel" }}
            >
              Logout
            </Button>
          ) : (
            <Button
              color="inherit"
              component={Link}
              to="/login"
              sx={{ fontSize: "1.3rem", fontFamily: "Corbel" }}
            >
              Login
            </Button>
          )}
        </Toolbar>
      </AppBar>
      {loggedIn ? <Sidebar open={open} /> : null}
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Toolbar />
        {props.children}
      </Box>
    </Box>
  );
}
