import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { axiosAuth } from "../interceptors";
import {
  Button,
  TextField,
  Box,
  MenuItem,
  RadioGroup,
  FormLabel,
  FormControlLabel,
  Radio,
  Autocomplete,
  Checkbox,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import dayjs from "dayjs";
import DatePickerClear from "../components/DatePickerClear";

export default function CreateItem() {
  const navigate = useNavigate();

  const [categories, setCategories] = useState([]);
  const [churches, setChurches] = useState([]);
  const [persons, setPersons] = useState([]);
  const [input, setInput] = useState({
    name: "",
    category: "",
    church: "",
    startDate: null,
    endDate: null,
    mailSignal: null,
    visualSignal: null,
    persons: null,
    note: null,
    archive: false,
  });

  useEffect(() => {
    axiosAuth
      .get(process.env.REACT_APP_SERVER_HOST + "/categorieen")
      .then((response) => {
        setCategories(response.data);
      });

    axiosAuth
      .get(process.env.REACT_APP_SERVER_HOST + "/kerkgebouwen")
      .then((response) => {
        setChurches(response.data);
      });

    axiosAuth
      .get(process.env.REACT_APP_SERVER_HOST + "/user")
      .then((response) => {
        setPersons(response.data);
      });
  }, []);

  function handleSubmit(event) {
    event.preventDefault();

    for (const category of categories) {
      if (category.naam === input.category) {
        input.category = category.id;
      }
    }

    for (const church of churches) {
      if (church.naam === input.church) {
        input.church = church.id;
      }
    }

    axiosAuth
      .post(process.env.REACT_APP_SERVER_HOST + "/items", {
        name: input.name,
        category: input.category,
        church: input.church,
        archive: input.archive,
        startDate: input.startDate,
        endDate: input.endDate,
        mailSignal: input.mailSignal,
        visualSignal: input.visualSignal,
        persons: input.persons,
        note: input.note,
      })
      .then((response) => {
        navigate("/");
      });
  }

  return (
    <>
      <Button variant="contained" color="primary" component={Link} to={"/"}>
        Terug
      </Button>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gap: "20px",
        }}
        component="form" onSubmit={handleSubmit} noValidate
      >
        {/* Left Side */}
        <Box>
          <TextField
            margin="normal"
            required
            fullWidth
            id="name"
            label="Naam"
            name="name"
            autoComplete="name"
            autoFocus
            onChange={(event) =>
              setInput({ ...input, name: event.target.value })
            }
          />

          <TextField
            select
            required
            label="Categorie"
            id="category"
            value={input.category}
            onChange={(event) =>
              setInput({ ...input, category: event.target.value })
            }
            autoFocus
            sx={{ width: "100%", mt: 1, mb: 1 }}
          >
            {categories.map((category) => (
              <MenuItem key={category.id} value={category.naam}>
                {category.naam}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            select
            label="Kerk"
            id="church"
            value={input.church}
            onChange={(event) =>
              setInput({ ...input, church: event.target.value })
            }
            autoFocus
            sx={{ width: "100%", mt: 1, mb: 1 }}
          >
            {churches.map((church) => (
              <MenuItem key={church.id} value={church.naam}>
                {church.naam}
              </MenuItem>
            ))}
          </TextField>

          <DatePickerClear
            onChange={(date) =>
              setInput({
                ...input,
                startDate: dayjs(date.$d).format("YYYY-MM-DD"),
              })
            }
            value={input.startDate == null ? null : dayjs(input.startDate)}
            onClear={() => setInput({ ...input, startDate: null })}
            label="Begindatum"
            id="startdate"
            sx={{ width: "100%", mt: 1, mb: 1 }}
          />

          <DatePickerClear
            onChange={(date) =>
              setInput({
                ...input,
                endDate: dayjs(date.$d).format("YYYY-MM-DD"),
              })
            }
            value={input.endDate == null ? null : dayjs(input.endDate)}
            onClear={() => setInput({ ...input, endDate: null })}
            label="Einddatum"
            id="enddate"
            sx={{ width: "100%", mt: 1, mb: 1, ml: 5 }}
          />

          <DatePickerClear
            onChange={(date) =>
              setInput({
                ...input,
                mailSignal: dayjs(date.$d).format("YYYY-MM-DD"),
              })
            }
            value={input.mailSignal == null ? null : dayjs(input.mailSignal)}
            onClear={() => setInput({ ...input, mailSignal: null })}
            label="Mail signaal"
            id="mailsignal"
            sx={{ width: "100%", mt: 1, mb: 1 }}
          />

          <DatePickerClear
            onChange={(date) =>
              setInput({
                ...input,
                visualSignal: dayjs(date.$d).format("YYYY-MM-DD"),
              })
            }
            value={
              input.visualSignal == null ? null : dayjs(input.visualSignal)
            }
            onClear={() => setInput({ ...input, visualSignal: null })}
            label="Visueel signaal"
            id="visualsignal"
            sx={{ width: "100%", mt: 1, mb: 1, ml: 5 }}
          />
        </Box>

        {/* Right Side */}
        <Box sx={{ mt: 2 }}>
          <Autocomplete
            key="persons"
            multiple
            id="checkboxes-tags-demo"
            limitTags={2}
            options={persons}
            disableCloseOnSelect
            getOptionLabel={(option) => option.email}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.voornaam && option.achternaam
                  ? option.voornaam + " " + option.achternaam
                  : option.email}
              </li>
            )}
            renderInput={(params) => (
              <TextField {...params} label="Personen" placeholder="Personen" />
            )}
            onChange={(e, value) => {
              setInput({ ...input, persons: value });
            }}
          />
          <TextField
            margin="normal"
            fullWidth
            id="notes"
            label="Notities"
            name="notes"
            autoComplete="notes"
            onChange={(event) =>
              setInput({ ...input, note: event.target.value })
            }
            autoFocus
            multiline
            rows={6}
          />

          <FormLabel component="archive-label">Archief</FormLabel>
          <RadioGroup
            row
            aria-labelledby="archive-label"
            id="archive"
            value={input.archive}
            onChange={(event) =>
              setInput({
                ...input,
                archive: event.target.value === "true" ? true : false,
              })
            }
            autoFocus
            sx={{ width: "100%", mt: 1, mb: 1 }}
          >
            <FormControlLabel value="true" control={<Radio />} label="Ja" />
            <FormControlLabel value="false" control={<Radio />} label="Nee" />
          </RadioGroup>
        </Box>

        {/* Centered Button */}
        <Box sx={{ gridColumn: "1 / -1", textAlign: "center" }}>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Voeg toe
          </Button>
        </Box>
      </Box>
    </>
  );
}
