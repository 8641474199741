import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { axiosAuth } from "../interceptors";
import {
  Container,
  Button,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Typography,
  Box
} from "@mui/material";
import dayjs from "dayjs";

const App = () => {
  let { id } = useParams();
  const [items, setItems] = useState([]);

  useEffect(() => {
    axiosAuth(
      process.env.REACT_APP_SERVER_HOST +
        "/items" +
        "/" +
        id
    ).then((response) => {
      // console.log(response.data);
      setItems(response.data);
    });
  }, []);

  const begindatum = items.begindatum
    ? dayjs(items.begindatum).format("YYYY-MM-DD")
    : "-";
  const einddatum = items.einddatum
    ? dayjs(items.einddatum).format("YYYY-MM-DD")
    : "-";
  const signaal_mail = items.signaal_mail
    ? dayjs(items.signaal_mail).format("YYYY-MM-DD")
    : "-";
  const signaal_visueel = items.signaal_visueel
    ? dayjs(items.signaal_visueel).format("YYYY-MM-DD")
    : "-";

  return (
    <Container>
      <Button variant="contained" color="primary" component={Link} to="/">
        Terug
      </Button>
      <Button
        variant="contained"
        color="primary"
        component={Link}
        to={"/item/edit/" + id}
        sx={{ ml: 2 }}
      >
        Bewerk
      </Button>
      <Typography
        variant="h4"
        component="h1"
        sx={{ mt: 4, fontWeight: "bold", mb: 2 }}
      >
        Detailpagina
      </Typography>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableRow>
            <TableCell variant="head" sx={{ fontWeight: "bold" }}>
              Naam:
            </TableCell>
            <TableCell>{items.naam}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head" sx={{ fontWeight: "bold" }}>
              Categorie:
            </TableCell>
            <TableCell>{items.categorie_naam}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head" sx={{ fontWeight: "bold" }}>
              Kerkgebouw:
            </TableCell>
            <TableCell>{items.kerk_naam || "-"}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head" sx={{ fontWeight: "bold" }}>
              Begindatum:
            </TableCell>
            <TableCell>{begindatum}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head" sx={{ fontWeight: "bold" }}>
              Einddatum:
            </TableCell>
            <TableCell>{einddatum}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head" sx={{ fontWeight: "bold" }}>
              Mail Signaal:
            </TableCell>
            <TableCell>{signaal_mail}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head" sx={{ fontWeight: "bold", minWidth: 180 }}>
              Visueel Signaal:
            </TableCell>
            <TableCell>{signaal_visueel}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head" sx={{ fontWeight: "bold" }}>
              Notitie:
            </TableCell>
            <TableCell>{items.notitie || "-"}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head" sx={{ fontWeight: "bold" }}>
              Personen:
            </TableCell>
            <TableCell>
              {items.personen?.length === 0 ? (
                <Typography variant="body2">
                  Geen personen gekoppeld
                </Typography>
              ) : (
                <Box sx={{ display: "flex"}}>
                  {items.personen?.map((persoon, index) => (
                    <Typography key={persoon.id} variant="body2" sx={{mr: 2}}>
                      {persoon.voornaam} {persoon.achternaam}
                    </Typography>
                  ))}
                </Box>
              )}
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell variant="head" sx={{ fontWeight: "bold" }}>
              Archief:
            </TableCell>
            <TableCell>
              {items.archief ? "Gearchiveerd" : "Niet gearchiveerd"}
            </TableCell>
          </TableRow>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default App;
