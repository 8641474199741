import React, { useState } from 'react';
import { Box, TextField, Button, Typography, Container } from '@mui/material';
import axios from 'axios';


export default function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [emailSent, setEmailSent] = useState(false);

  const handleResetPassword = () => {
    // Add here to handle the password reset request.
    // You can send the email/username and new password to your server for processing.
    
    if (email) {
      setEmailSent(true);
      axios.post(process.env.REACT_APP_SERVER_HOST +"/user/reset-password", {
          email: email
        }).then((response) => {
        }).catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <Container component="main" maxWidth="xs">
    <Box>
        <Box sx={{ mt: 1 }}>
          <Typography>Wachtwoord vergeten?</Typography>
          
          <TextField
            placeholder="Email adres"
            value={email}
            onChange={(text) => setEmail(text.target.value)}
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Adres"
            name="email"
            autoComplete="email"
            autoFocus
          />

          <Button
              onClick={handleResetPassword}
              type="button"
              fullWidth
              variant="contained"
              sx={{
                mt: 1,
                color: "white" 
              }}
            >
              Email verzenden
            </Button>
        </Box>
    </Box>

    { emailSent && (
        <Box sx={{ mt: 2 }}>
          <Typography>
            Er wordt een email verzonden naar {email} met instructies om je wachtwoord te resetten. Dit kan enkele minuten duren.
          </Typography>
        </Box>
    )}
    </Container>
  );
}