import React, { useState } from 'react';
import { Box, TextField, Button, Typography, Container } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import md5 from 'md5';

export default function NewPassword() {
  const navigate = useNavigate();
  const { token } = useParams();
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');

  const [error, setError] = useState(false);

  const handleResetPassword = () => {
    // Add code here to handle the password reset request.
    // You can send the email/username and new password to your server for processing.
  
    if (!password || password !== password2) {
      return setError(true);
    } else {
      axios.post(process.env.REACT_APP_SERVER_HOST +"/user/reset-password/" + token, {
          password: md5(password)
        }).then((response) => {
          if (response.status === 200) {
            navigate('/login');
          }
        }).catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box>
        <Box sx={{ mt: 1 }}>
          <Typography variant="h4">
            Reset Wachtwoord
          </Typography>

          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Nieuw wachtwoord"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={(text) => setPassword(text.target.value)}
          />

          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Nieuw wachtwoord herhalen"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={(text) => setPassword2(text.target.value)}
          />

          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={handleResetPassword}
            sx={{
              mt: 2,
            }}
          >
            Opslaan
          </Button>

        </Box>
      </Box>

      {error && (
        <Box sx={{ mt: 2 }}>
          <Typography>
            De wachtwoorden komen niet overeen
          </Typography>
        </Box>
      )}
    </Container>
  );
}
