import { Link, useNavigate } from "react-router-dom";
import { axiosAuth } from "../interceptors";
import { useState } from "react";
import { TextField, Container, Grid, Button, Box } from "@mui/material";

export default function CreateGebouw() {
  const navigate = useNavigate();
  const [input, setInput] = useState({
    name: "",
    address: "",
    city: "",
    postalCode: "",
  });

  function handleSubmit(event) {
    event.preventDefault();

    axiosAuth
      .post(
        process.env.REACT_APP_SERVER_HOST +
          "/kerkgebouwen",
        {
          name: input.name,
          address: input.address,
          city: input.city,
          postalCode: input.postalCode,
        }
      )
      .then((response) => {
        navigate("/gebouwen");
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function handleChange(e) {
    setInput({
      ...input,
      [e.target.name]: e.target.value,
    });
  }

  return (
    input && (
      <Container>
        <Button
          variant="contained"
          color="primary"
          component={Link}
          to="/gebouwen"
        >
          Terug
        </Button>
        <Box
          component="form"
          onSubmit={handleSubmit}
          noValidate
          sx={{ mt: 1, width: "30%" }}
        >
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={12}>
              <TextField
                id="outlined-required"
                label="Naam"
                name="name"
                onChange={handleChange}
                defaultValue={input.name}
                sx={{ width: 333, mt: 4 }}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="outlined-required"
                label="Adres"
                name="address"
                onChange={handleChange}
                defaultValue={input.address}
                sx={{ width: 333, mt: 2 }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="outlined-required"
                label="Postcode"
                name="postalCode"
                onChange={handleChange}
                defaultValue={input.postalCode}
                sx={{ width: 333, mt: 2 }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="outlined-required"
                label="Plaats"
                name="city"
                onChange={handleChange}
                defaultValue={input.city}
                sx={{ width: 333, mt: 2 }}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ width: 333, mt: 2 }}
              >
                Toevoegen
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Container>
    )
  );
}
