import { useParams, Link, useNavigate } from "react-router-dom";
import { axiosAuth } from "../interceptors";
import { useEffect, useState } from "react";
import {
  TextField,
  Container,
  Grid,
  Button,
  Box,
  Checkbox,
  Autocomplete,
} from "@mui/material";
import {
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
  CheckBox as CheckBoxIcon,
} from "@mui/icons-material";

export default function EditUser() {
  let { id } = useParams();
  const navigate = useNavigate();
  const [input, setInput] = useState();
  const [roles, setRoles] = useState([]);

  function fetchitemData() {
    axiosAuth(process.env.REACT_APP_SERVER_HOST + "/user/" + id).then(
      (response) => {
        setInput({
          email: response.data.email,
          firstName: response.data.voornaam,
          lastName: response.data.achternaam,
          phone: response.data.telefoon,
        });

        if (response.data.rollen) setRoles(response.data.rollen.split(","));
      }
    );
  }

  useEffect(() => {
    fetchitemData();
  }, []);

  const rolesOptions = ["admin", "superadmin"];

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  function handleSubmit(event) {
    event.preventDefault();
    axiosAuth
      .patch(process.env.REACT_APP_SERVER_HOST + "/user/" + id, {
        firstName: input.firstName,
        lastName: input.lastName,
        email: input.email,
        phone: input.phone,
        roles: roles.join(","),
      })
      .then((response) => {
        navigate("/gebruikers");
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function handleChange(e) {
    setInput({
      ...input,
      [e.target.name]: e.target.value,
    });
  }

  return (
    input && (
      <Container>
        <Button
          variant="contained"
          color="primary"
          component={Link}
          to="/gebruikers"
        >
          Terug
        </Button>
        <Box
          component="form"
          onSubmit={handleSubmit}
          noValidate
          sx={{ mt: 1, width: "30%" }}
        >
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={12}>
              <TextField
                id="outlined-required"
                label="Email"
                name="email"
                onChange={handleChange}
                defaultValue={input.email}
                sx={{ width: 333, mt: 4 }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="outlined-required"
                label="Voornaam"
                name="firstName"
                onChange={handleChange}
                defaultValue={input.firstName}
                sx={{ width: 333, mt: 2 }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="outlined-required"
                label="Achternaam"
                name="lastName"
                onChange={handleChange}
                defaultValue={input.lastName}
                sx={{ width: 333, mt: 2 }}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                multiple
                id="roles"
                limitTags={2}
                options={rolesOptions}
                value={roles}
                disableCloseOnSelect
                sx={{
                  flex: 1,
                  mt: 1,
                  width: 333,
                }}
                getOptionLabel={(option) => option}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={roles.includes(option)}
                    />
                    {option}
                  </li>
                )}
                isOptionEqualToValue={(option, value) => option === value}
                renderInput={(params) => (
                  <TextField {...params} label="Rollen" placeholder="Rollen" />
                )}
                onChange={(e, value) => {
                  setRoles(value);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="outlined-required"
                label="Telefoon"
                name="phone"
                onChange={handleChange}
                defaultValue={input.phone}
                sx={{ width: 333, mt: 2 }}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ width: 333, mt: 2 }}
              >
                Bewerk
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Container>
    )
  );
}
