import React, { useEffect, useState } from "react";
import "./App.css";
import { Route, BrowserRouter, Routes } from "react-router-dom";
import Navbar from "./components/Navbar";
import Login from "./pages/Login";
import Home from "./pages/Home";
import Overview from "./pages/Overview";
import Gebouwen from "./pages/OverviewGebouw";
import Categorieen from "./pages/OverviewCategorieen";
import Cookies from "js-cookie";
import { AppContext } from "./context/appContext";
import Users from "./pages/Users";
import Item from "./pages/Item";
import CreateItem from "./pages/CreateItem";
import EditItem from "./pages/EditItem";
import EditGebouw from "./pages/EditGebouw";
import EditCategorie from "./pages/EditCategorie";
import CreateGebouw from "./pages/CreateGebouw";
import CreateCategorie from "./pages/CreateCategorie";
import EditUser from "./pages/EditUser";
import ForgotPassword from "./pages/ForgotPassword";
import NewPassword from "./pages/NewPassword";
import MailArchief from "./pages/MailArchief";

function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [header, setHeader] = useState("Home");

  useEffect(() => {
    if (Cookies.get("loggedIn")) {
      setLoggedIn(true);
      setHeader("Overzicht");
    }
  }, [loggedIn]);

  return (
    <AppContext.Provider
      value={{
        loggedIn,
        setLoggedIn,
        header,
        setHeader,
      }}
    >
      <BrowserRouter>
        <Navbar>
          <Routes>
            {loggedIn ? (
              <>
                <Route exact path="/" element={<Overview />} />
                {/* Item */}
                <Route exact path="/item/:id" element={<Item />} />
                <Route exact path="/item/create" element={<CreateItem />} />
                <Route exact path="/item/edit/:id" element={<EditItem />} />
                {/* Categorieën */}
                <Route exact path="/categorieen" element={<Categorieen />} />

                <Route
                  exact
                  path="/categorieen/edit/:id"
                  element={<EditCategorie />}
                />

                <Route
                  exact
                  path="/categorieen/create"
                  element={<CreateCategorie />}
                />
                {/* Gebouwen */}
                <Route exact path="/gebouwen" element={<Gebouwen />} />

                <Route
                  exact
                  path="/gebouwen/edit/:id"
                  element={<EditGebouw />}
                />

                <Route
                  exact
                  path="/gebouwen/create"
                  element={<CreateGebouw />}
                />

                {/* Archief */}
                <Route exact path="/mailarchief" element={<MailArchief />} />

                {/* User */}

                <Route exact path="/gebruikers" element={<Users />} />
                <Route exact path="/gebruiker/:id" element={<EditUser />} />
              </>
            ) : (
              <>
                <Route exact path="/" element={<Home />} />
                <Route exact path="/login" element={<Login />} />
              </>
            )}

            <Route exact path="/login" element={<Login />} />
            <Route exact path="/reset-password" element={<ForgotPassword />} />
            <Route exact path="/reset-password/:token" element={<NewPassword />} />


          </Routes>
        </Navbar>
      </BrowserRouter>
    </AppContext.Provider>
  );
}

export default App;
