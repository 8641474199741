import md5 from "md5";
import { useNavigate, Link } from "react-router-dom";
import {
  Container,
  Box,
  TextField,
  Typography,
  Button,
  Snackbar,
  Alert,
  CircularProgress,
} from "@mui/material";

import { loginUser } from "../services/UserService";
import { useState } from "react";
import { useContext } from "react";
import { AppContext } from "../context/appContext";

function Login() {
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { setLoggedIn, setHeader } = useContext(AppContext);
  const [loading, setLoading] = useState(false);

  function handleSubmit(event) {
    setLoading(true);

    event.preventDefault();
    const data = new FormData(event.currentTarget);
    loginUser({
      email: data.get("email"),
      password: md5(data.get("password")),
    })
      .then((response) => {
        setHeader("Overzicht");
        setLoading(false);
        setLoggedIn(true);
        navigate("/");
      })
      .catch((error) => {
        setLoading(false);
        if (error.response?.status === 422) {
          setErrorMessage("Vul alle velden in");
        } else if (error.response?.status === 404) {
          setErrorMessage("Kan email niet vinden");
        } else if (error.response?.status === 401) {
          setErrorMessage("Wachtwoord is niet correct");
        } else {
          setErrorMessage("Er is iets misgegaan");
        }
        setError(true);
      });
  }

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          boxShadow: 3,
          borderRadius: 2,
          px: 4,
          py: 6,
          marginTop: 16,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h5">
          Login
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Adres"
            name="email"
            autoComplete="email"
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Wachtwoord"
            type="password"
            id="password"
            autoComplete="current-password"
          />

            <Button
              type="button"
              fullWidth
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                mt: -1,
                fontSize: "9px"
              }}
              component={Link}
              to="/reset-password" 
            >
              <i>Wachtwoord vergeten?</i>
            </Button>
          {loading ? (
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                mt: 2,
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 2, mb: 2 }}
            >
              Login
            </Button>
          )}
   
        </Box>
        
      </Box>
      <Snackbar
        open={error}
        autoHideDuration={6000}
        onClose={() => setError(false)}
      >
        <Alert severity="error" sx={{ width: "100%" }}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default Login;
