import { Box, Container, Typography } from "@mui/material";

export default function Home() {
  return (
    <Container sx={{display: 'flex', alignItems: 'center', flexDirection: 'column', height: '100%'}}>
      <Box>
        <img
          src={process.env.PUBLIC_URL + "/pgzlogometnaam.png"}
          alt="logometnaam"
        />
      </Box>
      <Typography variant="h4" sx={{textAlign: 'center', color: 'orange'}}>
        Kerkelijk Bureau <br />
        Registratiesysteem contracten en overeenkomsten
      </Typography>
      <Box sx={{height: 400, display: 'flex', justifyContent: 'center'}}>
        <img
          src={process.env.PUBLIC_URL + "/home.jpeg"}
          alt="home"
          style={{maxWidth: '100%', maxHeight: '100%'}}
        />
      </Box>
    </Container>
  );
}
