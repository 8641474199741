import React, { useEffect, useState } from "react";
import { axiosAuth } from "../interceptors";
import OverviewTable from "../components/OverviewTable";

export default function MailArchief() {
  const [mails, setMails] = useState([]);

  const fetchitemData = () => {
    axiosAuth(process.env.REACT_APP_SERVER_HOST + "/mailarchief").then(
      (response) => {
        setMails(response.data);
      }
    );
  };

  useEffect(() => {
    fetchitemData();
  }, []);

  const headCells = [
    {
      id: "verzenddatum",
      label: "Verzenddatum",
      sort: true,
      style: { width: "30%" },
      type: "date",
    },
    {
      id: "item",
      label: "Item",
      sort: true,
      style: { width: "35%" },
    },
    {
      id: "ontvanger",
      label: "Ontvanger",
      sort: true,
      style: { width: "35%" },
    },
  ];

  return (
    <OverviewTable
      title="Mail Archief"
      headCells={headCells}
      rows={mails}
    />
  );
}
